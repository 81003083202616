.prayer-images-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adds space between the images */
}

.left-image {
    width: 45%; /* Adjusted to allow side-by-side display */
}

@media (max-width: 768px) {
    .prayer-images-container {
        flex-direction: column;
        align-items: center;
    }

    .left-image {
        width: 95%;
    }
}
