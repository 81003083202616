.accordion-custom {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    background-color: #f9f9f9;
    width: 100%;
    margin-left: 0; /* Align to the left */
  }
  
  .accordion-header-custom {
    text-align: left; /* Align header text to the left */
  }
  
  .accordion-body-custom {
    text-align: left; /* Align body text to the left */
  }
  
  .accordion-container {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: flex-start;
    flex-direction: column; /* Ensure items stack vertically */
  }