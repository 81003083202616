.navbar {
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid #333; 
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  height: 80px;
  position: relative;
}

.nav-logo {
  display: flex;
  align-items: center;
}

.nav-logo-img {
  width: auto;
  height: 4rem;
  margin: 10px 0;
  padding-bottom: 5px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin: 0; /* Ensure there is no margin */
  padding: 0; /* Ensure there is no padding */
}

.nav-links {
  color: #30482E;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  line-height: 40px;
  margin: 0 1rem; /* Ensure consistent margin */
  width: 150px;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;

}

.nav-icon {
  display: none;
  color: #30482E;
}

.nav-icon .icones {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-container {
      padding: 0 1rem;
  }

  .nav-menu {
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
  }

  .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
  }

  .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
      text-align: left;
  }

  .nav-logo .icon {
      width: 2rem;
      height: 2rem;
  }

  .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #30482E;
  }
}

@media screen and (min-width: 961px) {
  .nav-container {
      padding-left: 25%;
  }
}
