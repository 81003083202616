.uniform-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.uniform-card .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-img-top {
    max-height: 300px;
    
}

.middle-image {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50dvh;
}

.middle-image button {
    margin: auto;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .card-img-top {
        max-height: 200px;
    }
}

@media (max-width: 576px) {
    .card-img-top {
        max-height: 150px;
    }
}